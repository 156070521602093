<template>
	<div :class="is_ph?'ph_mask active' : 'ph_mask'" @click.self="closeShopList">
		<div class="ph_main" v-loading="viewLoading">
			<i class="iconfont icon-guanbi" @click.self="closeShopList"></i>
			<p class="title">铺货</p>
			<div class="ph_sel" v-if="shopList.length>0">
				<el-checkbox-group v-model="checkList" @change="selectShop">
					<div class="sel_box" v-for="(shop,index) in shopList" :key="index">
						<el-checkbox :label="shop.shop_id" :key="shop.shop_id" v-model="shop.is_select"></el-checkbox>
						<!-- <img class="logo" :src="shop.authorize_url" /> -->
						<img class="logo" src="../../assets/images/logo.png" />
						<p class="name">{{shop.shop_name}}</p>
            <div style="font-size: 12px;margin:8px 0 8px 18px;display: flex;align-items: center">
              搬家配置：
              <el-select v-model="peiz" placeholder="请选择" size="mini" style="width: 100px">
                <el-option
                    label="默认配置"
                    value="默认配置">
                </el-option>
              </el-select>
            </div>
            <a type="text" size="mini" @click="goToEdit(shop.shop_id)" style="padding-left: 0;margin-left: 18px;font-size: 12px;color: red">去配置</a>
            <div style="display: flex;align-items: center;margin-top: 10px;margin-left: 18px;font-size: 12px">
              <p class="dp_status">复制额度</p>
              <el-tooltip class="item" effect="dark" content="" placement="bottom">
                <div slot="content" style="width: 300px">
                  购买星好货铺品工具，赠送1000个商品铺货额度，免费额度使用完需要用户购买铺品额度。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
              <p class="btn_auth red" @click="toRecharge(shop)" style="margin-left: 5px">
                去充值</p>
            </div>
            <div class="dp_status" style="font-size: 12px;margin-left: 18px;margin-top: 5px">剩余额度 {{ shop.quota_num }} / 总铺货额度 {{ shop.quota_total_num }}</div>
					</div>
				</el-checkbox-group>
			</div>
			<el-empty :image-size="50" v-else description="暂无数据"></el-empty>
			<div class="ph_total">
				<el-checkbox v-model="all_select" :indeterminate="all_indeterminate" @change="checkAll()">全选</el-checkbox>
				<el-button type="danger" :disabled="dialog.editPriceDialog || buttonLoading" 
						   @click="bindBatEditPrice()" 
						   :loading="buttonLoading">立即铺货</el-button>
			</div>
		</div>
		<!-- 选择改价策略 -->
		<el-dialog title="上架策略" :visible.sync="dialog.editPriceDialog" width="500px"  top="30vh" :modal="false">
			<pricePloy @submit="submit" @close="dialog.editPriceDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.editPriceDialog"></pricePloy>
		</el-dialog>
    <Recharge ref="recharge" @success="onRecharge"></Recharge>
	</div>
</template>

<script>
import pricePloy from '@/components/pricePloy/pricePloy';
import Recharge from "@/components/recharge/Recharge.vue";
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	components:{
		pricePloy,Recharge
	},
	name: "shopList",
	props: {
    expire_val:{
      type:[String,Number],
      default:''
    }
	},
	data() {
		return {
			viewLoading:false,
			is_ph:false,
			shopList:[],
			all_indeterminate:false,
			all_select:false,
			checkList: [],
			shopIdlist:[],
			isLoading: false,
			dialog: {
				editPriceDialog:false,
			},
      urlTo:'',
      peiz:'默认配置',
      buttonLoading: false,
		};
	},
	
	created(){
	},
	mounted() {
	},
	computed:{
		...mapState({
		}),
	},
	methods: {
		...mapActions({
			getDdShopList:"ucenter/getDdShopList",
      getShopEditUrl:"ucenter/getShopEditUrl"
		}),
    toRecharge(row){
      this.$refs.recharge.show(row)
    },
    onRecharge(){
      this.showShopList()
    },
		bindBatEditPrice(){
			let checkList = this.checkList
			if(checkList.length == 0){
				this.$message({message: '请勾选店铺',type: 'error'})
				return;
			}
			this.dialog.editPriceDialog = true
		},
    getEditUrl(){
      this.getShopEditUrl({data:{},success:(res)=>{
          if(res.code===200){
            this.urlTo = res.data.url
          }
        }})
    },
    goToEdit(id){
      window.open(this.urlTo + '&shopId='+id)
      // urlTo
    },
		closeShopList(){
			this.is_ph = false
			this.checkList = []
			this.all_indeterminate = false
			this.all_select = false
			this.buttonLoading = false
		},
		showShopList(){
      this.getEditUrl()
			setTimeout(()=>{this.viewLoading = true},100)
			// this.viewLoading = true
			let param = {limit:30,page:1, status: 1}
			// let param = {limit:30,page:1}
      if(this.expire_val){
        param.expire_val = this.expire_val
      }
			this.getDdShopList({data:param,success:(res)=>{
				if(res.code===200){
					this.is_ph = true
					let arr = JSON.parse(JSON.stringify(res.data.list_data.data))
					for(let i in arr){
						arr[i].is_select = true
					}
					this.shopList = arr
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				setTimeout(()=>{this.viewLoading = false},1000)
				// this.viewLoading = false
			}})
		},
		selectShop(val){
			this.shopIdlist = val
			if(this.shopIdlist.length == this.shopList.length){
				this.all_indeterminate = false
				this.all_select = true
			}else if(this.shopIdlist.length == 0){
				this.all_indeterminate = false
				this.all_select = false
			}else{
				this.all_indeterminate = true
				this.all_select = false
			}
		},
		checkAll(){
			let list = JSON.parse(JSON.stringify(this.shopList))
			this.checkList = []
			if(this.all_select){
				for(let i in list){
					this.checkList.push(list[i].shop_id)
				}
				this.all_indeterminate = false
				this.all_select = true
			}else{
				this.all_indeterminate = false
				this.all_select = false
			}
			this.shopIdlist = this.checkList
		},
		
		submit(strategy){
			if(this.shopIdlist.length==0){
				this.$message({message: '请勾选店铺！',type: 'error',});
				return false
			}
			this.dialog.editPriceDialog = false
			this.isLoading = false
			this.buttonLoading = true
			let ids = JSON.parse(JSON.stringify(this.shopIdlist)).toString()
			this.$parent.phSubmit(ids, strategy);
		},
	},

}
</script>

<style scoped>
.ph_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.ph_mask.active{visibility: visible;opacity: 1;}
.ph_mask .ph_main{width: 800px;height: auto;box-sizing: border-box;padding: 30px 30px 40px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.ph_mask.active .ph_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.ph_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.ph_main .title{font-size: 20px;margin-bottom: 6px;}
.ph_main .ph_sel{padding: 0 24px 20px;border-bottom: 1px solid #eeeeee;display: flex;flex-wrap: wrap;}
.ph_main .ph_sel .sel_box{width: 200px;margin: 28px 30px 0 0;display: flex;flex-direction: column;cursor: pointer;}
.ph_main .ph_sel .sel_box .logo{width: 56px;height: 56px;margin: 4px 0 0 18px;}
.ph_main .ph_sel .sel_box .name{margin: 15px 0 0 18px;text-align: left;font-size: 14px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.ph_main .ph_total{margin-top: 20px;display: flex;justify-content: space-between;align-items: center;}
.ph_main .ph_total .btn{width: 160px;height: 40px;border-radius: 6px;background: #f0413e;display: flex;justify-content: center;align-items: center;cursor: pointer;font-size: 14px;color: #fff;}
.el-checkbox-group{display: flex;flex-wrap: wrap;}
</style>
<style>
	.el-button--danger{background: #f0413e;}
.ph_main .ph_sel .el-checkbox__label{display: none !important;}
.el-empty{background-color: #fff;margin-top: 20px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 18px;height: 18px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 16px;line-height: 1;}
.el-checkbox__inner::after{height: 10px;left: 6px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}
</style>
